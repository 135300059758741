import {
  Button,
  Chip,
  Collapse,
  Dialog,
  Divider,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { connect } from 'redux-bundler-react';
import { sortBy } from 'lodash';
import { useDebounce } from 'use-debounce';
import { useHotkeys } from 'react-hotkeys-hook';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CakeIcon from '@material-ui/icons/Cake';
import CategoryIcon from '@material-ui/icons/Category';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import cx from 'classnames';
import matchSorter from 'match-sorter';

import { TransitionGroup } from 'react-transition-group';

import Client from '../../../models/client';
import Facility from '../../../models/facility';
import Recents from './Recents';
import Search from '../../../models/search';

const ICONS = {
  Facility: LocationCityIcon,
  Workstation: DesktopWindowsIcon,
  ImagePipeline: CakeIcon,
  Client: BusinessCenterIcon,
  Template: CategoryIcon,
  Group: GroupWorkIcon,
  ChefPackage: ListAltIcon,
  ClientUserAssignment: PersonIcon,
};

const styles = theme => ({
  help: {
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  footer: {
    borderTop: `1px solid rgba(0, 0, 0, 0.23)`,
    padding: theme.spacing(1.5),
  },
  form: {},
  input: {
    border: 'none',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  list: {
    maxHeight: 'calc(90vh - 64px)',
    overflowY: 'scroll',
  },
  chip: {
    borderRadius: 8,

    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(10),
    },
  },
  right: {
    textAlign: 'right',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  cancel: {
    textAlign: 'right',

    borderBottom: `1px solid rgba(0, 0, 0, 0.23)`,
  },

  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  key: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace",
    backgroundColor: theme.palette.background.paper,
    fontSize: '12px',
    borderRadius: 4,
    paddingLeft: 4,
    paddingRight: 4,
  },

  favourite: {
    opacity: 0,
  },

  selected: {
    '& $favourite': {
      opacity: 1,
    },
  },
  client: {
    '&:hover $favourite': {
      opacity: 1,
    },
  },
});

const isSearchable = item => {
  return item.searchable_type === 'Facility';
};

const SHORTCUTS = [
  {
    is_shortcut: true,
    type: 'Workstation',
    name: 'Workstations',
    auto: true,
    facility: true,
    getUrl: (client, facility) =>
      `/clients/${client.id}/facilities/${facility.id ||
        facility.searchable_id}/workstations`,
  },
  {
    is_shortcut: true,
    type: 'ImagePipeline',
    name: 'Image Pipelines',
    auto: true,

    facility: true,
    getUrl: (client, facility) =>
      `/clients/${client.id}/facilities/${facility.id ||
        facility.searchable_id}/image_pipelines`,
  },
  {
    is_shortcut: true,
    type: 'Template',
    name: 'Templates',
    auto: true,
    facility: true,
    getUrl: (client, facility) =>
      `/clients/${client.id}/facilities/${facility.id ||
        facility.searchable_id}/templates`,
  },
  {
    is_shortcut: true,
    type: 'Group',
    name: 'Groups',
    auto: true,
    facility: true,
    getUrl: (client, facility) =>
      `/clients/${client.id}/facilities/${facility.id ||
        facility.searchable_id}/groups`,
  },
  {
    is_shortcut: true,
    type: 'ChefPackage',
    searchable_type: 'ChefPackage',
    name: 'Script Packages',
    auto: true,
    facility: false,

    getUrl: (client, facility) =>
      `/clients/${client.id}/facilities/${facility.id ||
        facility.searchable_id}/chef_packages`,
  },

  {
    is_shortcut: true,
    type: 'ClientUserAssignment',
    searchable_type: 'ClientUserAssignment',
    name: 'Users',
    auto: true,
    facility: false,

    getUrl: (client, facility) => `/clients/${client.id}/users`,
  },
];

const OmniBar = ({
  classes,
  activeClient,
  activeFacility,
  doUpdateUrl,
  clientData,
  recentItems,
  favouriteClients,

  doUnfavouriteClient,
  doAddFavouriteClient,
  showSearch,
  onShowSearch,
  onCloseSearch,
  doSetNextClient,
}) => {
  // const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openRecents, setOpenRecents] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [client, setClient] = React.useState(activeClient);
  const [facility, setFacility] = React.useState();
  const [lastActiveFacility, setLastActiveFacility] = React.useState();
  const [lastActiveClient, setLastActiveClient] = React.useState();
  const [result, setResults] = React.useState(null);

  const [resultsWithShortcuts, setResultsWithShortcuts] = React.useState(null);

  const [debouncedQuery] = useDebounce(query, 100);
  const inputEl = React.useRef(null);
  const [selected, setSelected] = React.useState(0);
  const [filter, setFilter] = React.useState(null);
  const [placeholder, setPlaceholder] = React.useState(null);

  const [filteredClients, setFilteredClients] = React.useState([]);
  const [lastFavourite, setLastFavourite] = React.useState([]);

  const lastFavouriteRefCallback = React.useCallback(
    ref => {
      if (!ref) return;

      ref.scrollIntoView(false);
    },
    [selected],
  );

  const listItemRefCallback = React.useCallback(
    ref => {
      if (!ref) return;

      ref.scrollIntoView(false);
    },
    [selected],
  );

  const navigateToUrl = (url, openInNewTab, client) => {
    doSetNextClient(client);
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      doUpdateUrl(url);
    }
  };

  const navigateToResult = (result, openInNewTab, tabKey) => {
    if (!client) {
      if (tabKey) {
        setClient(result);
        inputEl.current.focus();
      } else {
        navigateToUrl(`/clients/${result.id}`, openInNewTab, result);
        onCloseSearch();
      }
      setQuery('');
      setSelected(0);
      return;
    }
    if (!filter && result.is_shortcut) {
      if (tabKey) {
        setFilter(result);
        inputEl.current.focus();
      } else {
        console.log('hello!!');
        navigateToUrl(
          result.url || result.getUrl(client, facility),
          openInNewTab,
        );

        onCloseSearch();
      }
      return;
    }

    if (!facility && isSearchable(result)) {
      if (tabKey) {
        setQuery('');
        setFacility(result);
        setResults(null);

        inputEl.current.focus();
        return;
      }
    }
    if (result.searchable_type === 'Workstation') {
      navigateToUrl(
        `/clients/${result.client_id}/facilities/${result.facility_id}/workstations/${result.searchable_id}`,
        openInNewTab,
        client,
      );
      onCloseSearch();
    }
    if (result.searchable_type === 'ImagePipeline') {
      navigateToUrl(
        `/clients/${result.client_id}/facilities/${result.facility_id}/image_pipelines/${result.searchable_id}`,
        openInNewTab,

        client,
      );
      onCloseSearch();
    }

    if (result.searchable_type === 'Template') {
      navigateToUrl(
        `/clients/${result.client_id}/facilities/${result.facility_id}/templates/${result.searchable_id}`,
        openInNewTab,
        client,
      );
      onCloseSearch();
    }

    if (result.searchable_type === 'Group') {
      navigateToUrl(
        `/clients/${result.client_id}/facilities/${result.facility_id}/groups/${result.searchable_id}`,
        openInNewTab,
        client,
      );
      onCloseSearch();
    }

    if (result.searchable_type === 'Facility') {
      navigateToUrl(
        `/clients/${result.client_id}/facilities/${result.facility_id}`,
        openInNewTab,
        client,
      );
      onCloseSearch();
    }

    if (result.searchable_type === 'ClientUserAssignment') {
      if (activeFacility && activeFacility.clientId === client.id) {
        navigateToUrl(
          `/clients/${result.client_id}/facilities/${activeFacility.id}/users/${result.user_id}`,
          openInNewTab,
          client,
        );
      } else {
        navigateToUrl(
          `/clients/${result.client_id}/users/${result.user_id}`,
          openInNewTab,
          client,
        );
      }
      onCloseSearch();
    }

    console.log(result.searchable_type);
    if (result.searchable_type === 'ChefPackage') {
      if (activeFacility && activeFacility.clientId === client.id) {
        navigateToUrl(
          `/clients/${result.client_id}/facilities/${activeFacility.id}/chef_packages/${result.searchable_id}`,
          openInNewTab,
          client,
        );
      } else {
        navigateToUrl(
          `/clients/${result.client_id}/chef_packages/${result.searchable_id}`,
          openInNewTab,
          client,
        );
      }
      onCloseSearch();
    }
  };

  const handleKeyDown = e => {
    console.log(e.key);
    let items;
    if (!client) items = filteredClients;

    items = items ||= result?.hasResults()
      ? resultsWithShortcuts
      : SHORTCUTS.filter(f => f.facility);
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (showSearch && items[selected]) {
        navigateToResult(
          items[selected],
          e.metaKey || e.ctrlKey,
          e.key === 'Tab',
        );
      }
      e.preventDefault();
    }
    if (e.key === 'ArrowDown') {
      if (showSearch && items) {
        if (items.length - 1 > selected) {
          setSelected(selected + 1);
        } else {
          setSelected(0);
        }
      }
      e.preventDefault();
    }

    if (e.key === 'Backspace') {
      if (query) {
        return;
      }

      if (filter) {
        setFilter(null);
        return;
      }

      if (facility) {
        setFacility(null);
        return;
      }

      setClient(null);
      setResults(null);
    }

    if (e.key === 'ArrowUp') {
      if (showSearch && items) {
        if (selected > 0) {
          setSelected(selected - 1);
        } else {
          setSelected(items.length - 1);
        }
      }
      e.preventDefault();
    }

    if (e.key === 'Escape') {
      onCloseSearch();
      e.preventDefault();
    }
  };

  const doQuery = async _query => {
    let facilityId = facility?.id || facility?.searchable_id;
    if (filter && !filter.facility) {
      facilityId = null;
    }
    console.log(_query);
    if (!facility && !filter) {
      if (_query === '' && !client) {
        return;
      }
      console.log('executing search', _query);
      const search = new Search({
        query: _query,
        client: client,
        queryType: 'Facility',
      });
      await search.save({ with: ['client.id'] });
      setResults(search);

      setSelected(0);
      return;
    }
    if (filter?.auto || query) {
      console.log('executing search 2', _query);
      const search = new Search({
        query: _query,
        client: client,

        facilityId: facilityId,
        queryType: filter?.type,
      });
      console.log(search);
      await search.save({ with: ['client.id'] });
      setResults(search);

      setSelected(0);
    } else {
      setResults(null);
      setSelected(0);
    }
  };

  useHotkeys(
    'ctrl+k',
    () => {
      const nextOpen = !showSearch;
      if (nextOpen) {
        onShowSearch();
      } else {
        onCloseSearch();
      }

      if (nextOpen) {
        setOpenRecents(false);
        // inputEl.current.select();
      }
    },
    { enableOnFormTags: true, preventDefault: true },

    [showSearch, openRecents],
  );

  useHotkeys(
    'ctrl+l',
    () => {
      const nextOpenRecents = !openRecents;
      setOpenRecents(nextOpenRecents);

      if (nextOpenRecents) {
        onCloseSearch();
        // inputEl.current.select();
      }
    },
    { enableOnFormTags: true, preventDefault: true },

    [showSearch, openRecents],
  );

  useHotkeys(
    'Escape',
    () => {
      if (showSearch) {
        onCloseSearch();
      }
    },
    { enableOnFormTags: true },

    [showSearch],
  );

  React.useEffect(() => {
    doQuery(debouncedQuery);
    console.log('query changed');
  }, [debouncedQuery, filter, client, facility]);

  React.useEffect(() => {
    if (activeClient?.id !== lastActiveClient?.id) {
      setLastActiveClient(activeClient);
      setClient(activeClient);
    }
  }, [activeClient]);

  React.useEffect(() => {
    if (activeFacility?.id !== lastActiveFacility?.id) {
      setLastActiveFacility(activeFacility);
      setFacility(activeFacility);
    }
  }, [activeFacility]);

  React.useEffect(() => {
    if (!client) {
      if (query !== '') {
        setFilteredClients(
          matchSorter(clientData, query.trim(), {
            keys: ['name'],
          }),
        );
        setSelected(0);
      } else {
        setFilteredClients(
          sortBy(clientData, c => !favouriteClients.includes(c.id)),
        );
      }
    }
  }, [client, query, clientData, favouriteClients]);

  React.useEffect(() => {
    if (!client) {
      setPlaceholder('Search for client');
      return;
    }

    if (filter) {
      setPlaceholder(`Search ${filter.name}`);
      return;
    }

    if (!facility) {
      setPlaceholder('Search for facility');
      return;
    }
    if (facility && !filter) {
      setPlaceholder(`Search ${facility.name}`);
      return;
    }
  }, [client, filter, facility]);

  React.useEffect(() => {
    if (!result) {
      setResultsWithShortcuts([]);
      return;
    }
    let shortcuts = [];

    if (!query && !filter) {
      if (facility) {
        shortcuts = SHORTCUTS.filter(f => f.facility);
      } else {
        shortcuts = SHORTCUTS.filter(f => !f.facility);
      }
    }

    setResultsWithShortcuts([...result?.results, ...shortcuts]);
  }, [facility, result, query]);

  const FilterIcon = filter && ICONS[filter.type];

  const handleSetClient = async recentClient => {
    onShowSearch();
    const response = await Client.find(recentClient.clientId);
    setClient(response.data);
    setFacility(null);
    setOpenRecents(false);
  };

  const handleSetFacility = async recentFacility => {
    onShowSearch();
    const response = await Client.find(recentFacility.clientId);
    setClient(response.data);

    const facilityResponse = await Facility.find(recentFacility.facilityId);
    setFacility(facilityResponse.data);
    setOpenRecents(false);
  };

  return (
    <>
      <Recents
        open={openRecents}
        onClose={() => setOpenRecents(false)}
        onSetClient={handleSetClient}
        onSetFacility={handleSetFacility}
      />
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="confirmation-dialog-title"
        open={showSearch}
        onClose={() => onCloseSearch()}
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        {fullScreen && (
          <div className={classes.cancel} onClick={() => onCloseSearch()}>
            <Button>Cancel</Button>
          </div>
        )}
        <div className={classes.form}>
          <InputBase
            inputRef={inputEl}
            className={classes.input}
            value={query}
            onChange={e => setQuery(e.target.value)}
            autoFocus
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            startAdornment={
              <>
                {client && (
                  <Slide in={client} direction={'right'}>
                    <InputAdornment position="start">
                      <Chip
                        icon={<BusinessCenterIcon />}
                        color="secondary"
                        className={classes.chip}
                        label={client.name}
                        onClick={() => {
                          setClient(null);
                          setFacility(null);
                          setFilter(null);

                          inputEl.current.focus();
                        }}
                      />
                    </InputAdornment>
                  </Slide>
                )}

                {facility && (
                  <Slide in={facility} direction={'right'}>
                    <InputAdornment position="start">
                      <Chip
                        color="secondary"
                        icon={<LocationCityIcon />}
                        className={classes.chip}
                        label={facility.name}
                        onClick={() => {
                          setFacility(null);
                          setFilter(null);

                          inputEl.current.focus();
                        }}
                      />
                    </InputAdornment>
                  </Slide>
                )}
                {filter && (
                  <Slide in={filter} direction={'right'}>
                    <InputAdornment position="start">
                      <Chip
                        color="secondary"
                        icon={<FilterIcon />}
                        className={classes.chip}
                        label={filter?.name}
                        onClick={() => {
                          setFilter(null);
                          inputEl.current.focus();
                        }}
                      />
                    </InputAdornment>
                  </Slide>
                )}
              </>
            }
          />
        </div>
        {client && result?.hasResults() && (
          <Collapse in={result?.hasResults()}>
            <Divider />
            <List className={classes.list} dense>
              <TransitionGroup>
                {resultsWithShortcuts.map((r, index) => {
                  const Icon = ICONS[r.searchable_type];
                  return (
                    <Collapse key={r.searchable_id}>
                      <ListItem
                        button
                        ref={index === selected ? listItemRefCallback : null}
                        selected={index === selected}
                        onClick={() => {
                          navigateToResult(r, false, true);
                          /* onCloseSearch(); */
                        }}
                      >
                        <ListItemIcon className={classes.icon}>
                          {Icon && <Icon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={r.name}
                          /* secondary={humanize(underscore(r.searchable_type))} */
                        />

                        {index === selected && isSearchable(r) && (
                          <ListItemText
                            secondary={
                              <>
                                {isSearchable(r) ? (
                                  <>
                                    <kbd className={classes.key}>enter</kbd> to
                                    jump to{' '}
                                    <kbd className={classes.key}>tab</kbd> to
                                    search
                                  </>
                                ) : (
                                  <>
                                    Press{' '}
                                    <kbd className={classes.key}>enter</kbd> to
                                    select
                                  </>
                                )}
                              </>
                            }
                            className={classes.right}
                            secondaryTypographyProps={{ variant: 'body2' }}
                          />
                        )}
                      </ListItem>
                    </Collapse>
                  );
                })}
              </TransitionGroup>
            </List>
          </Collapse>
        )}

        <Collapse
          in={client && facility && !query && !filter && !result?.hasResults()}
        >
          <Divider />
          <List dense>
            {SHORTCUTS.filter(f => f.facility).map((r, index) => {
              const Icon = ICONS[r.type];
              return (
                <ListItem
                  button
                  ref={index === selected ? listItemRefCallback : null}
                  selected={index === selected}
                  onClick={() => {
                    navigateToResult(r, false, true);
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    {Icon && <Icon />}
                  </ListItemIcon>
                  <ListItemText primary={r.name} />

                  {index === selected && (
                    <ListItemText
                      secondary={
                        <>
                          <kbd className={classes.key}>enter</kbd> to jump to{' '}
                          <kbd className={classes.key}>tab</kbd> to search
                        </>
                      }
                      className={classes.right}
                      secondaryTypographyProps={{ variant: 'body2' }}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Collapse>

        {!client && (
          <>
            <Divider />
            <Flipper
              flipKey={filteredClients.map(r => r.id).join('')}
              className={classes.list}
            >
              <List dense>
                {filteredClients.map((r, index) => (
                  <Flipped key={r.id} flipId={r.id}>
                    <ListItem
                      id={r.id}
                      button
                      onClick={() => {
                        navigateToResult(r, false, true);
                      }}
                      ref={index === selected ? listItemRefCallback : null}
                      selected={index === selected}
                      className={cx(
                        classes.client,
                        index === selected && classes.selected,
                      )}
                    >
                      <ListItemIcon className={classes.icon}>
                        <BusinessCenterIcon />
                      </ListItemIcon>
                      <ListItemText primary={r.name} />

                      <ListItemText
                        secondary={
                          <div className={classes.rightContainer}>
                            {index === selected && (
                              <div className={classes.help}>
                                <kbd className={classes.key}>enter</kbd> to jump
                                to <kbd className={classes.key}>tab</kbd> to
                                search
                              </div>
                            )}
                            {favouriteClients.includes(r.id) ? (
                              <StarIcon
                                onClick={e => {
                                  doUnfavouriteClient(r.id);

                                  inputEl.current.focus();
                                  e.stopPropagation();
                                }}
                              />
                            ) : (
                              <StarBorderIcon
                                className={classes.favourite}
                                onClick={async e => {
                                  setLastFavourite(r);
                                  inputEl.current.focus();
                                  /* const sleep = milliseconds => { */
                                  /*   return new Promise(resolve => */
                                  /*     setTimeout(resolve, milliseconds), */
                                  /*   ); */
                                  /* }; */
                                  /* await sleep(500); */
                                  doAddFavouriteClient(r.id);
                                  e.stopPropagation();
                                }}
                              />
                            )}
                          </div>
                        }
                        className={classes.right}
                        secondaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>
                  </Flipped>
                ))}
              </List>
            </Flipper>
          </>
        )}

        <div className={classes.footer}>
          <Typography variant="caption" color="textSecondary">
            <kbd className={classes.key}>ctrl+l</kbd> Jump to recent items{' '}
          </Typography>
        </div>
      </Dialog>
    </>
  );
};

export default connect(
  'doUpdateUrl',
  'selectActiveClient',
  'selectActiveFacility',
  'selectFavouriteClients',

  'selectClientData',
  'selectRecentItems',
  'doUnfavouriteClient',
  'doAddFavouriteClient',
  'doSetNextClient',
  withStyles(OmniBar, styles, { withTheme: true }),
);
