import { Attr, BelongsTo, HasMany, HasOne, Model, Link } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import AvailabilityZone from './availability_zone';
import Facility from './facility';
import Group from './group';
import Image from './image';
import InstanceType from './instance_type';
import Template from './template';
import WorkstationConnection from './workstation_connection';
import WorkstationCreation from './workstation_creation';
import WorkstationCreationGroup from './workstation_creation_group';
import WorkstationDeletion from './workstation_deletion';
import WorkstationEventFeedEntry from './workstation_event_feed_entry';
import WorkstationGroupAssignment from './workstation_group_assignment';
import WorkstationLogin from './workstation_login';
import WorkstationBoot from './workstation_boot';
import User from './user';
// import WorkstationStat from './workstation_stat';
import Stat from './stat';

@Model()
export default class Workstation extends ApplicationRecord {
  static jsonapiType = 'workstations';
  @Attr() name: string;
  @Attr() ipAddress: string;
  @Attr() instanceId: string;
  @Attr() stackId: string;
  @Attr() stackName: string;
  @Attr() isFailed: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() pending: boolean;
  @Attr() multiAz: boolean;
  @Attr() ebsRaid: boolean;
  @Attr() instanceState: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() discardedAt: string;
  @Attr() availabilityZoneIdentifier: string;
  @Attr() ssmConnectionCommand: string;
  @Attr() pcoipActive: boolean;
  @Attr() pcoipVersion: string;
  @Attr() pcoipAgentUpdatePending: boolean;
  @Attr() pendingBoot: boolean;
  @Attr() pendingRebuild: boolean;
  @Attr() pendingShutdown: boolean;
  @Attr() latest: boolean;
  @Attr() locked: boolean;
  @Attr() platform: string;
  @Attr() rebuiltAt: string;
  @Attr() deploymentType: string;

  @Attr() render: boolean;
  @Attr() enableParsec: boolean;
  @Attr() enableNiceDcv: boolean;
  @Attr() parsecPeerId: string;

  @Attr() pendingIceBoot: boolean;
  @Attr() pendingIceBootAttempts: number;
  @Attr() maneAgentBuildVersion: string;
  @Attr() maneAgentUpdatePending: boolean;
  @Attr() idleShutdownEnabled: boolean;

  @Attr() lastLoginAt: string;
  @Attr() lastLoggedOutAt: string;
  @Attr() lastLoginActive: boolean;
  @Attr() lastConnectionActive: boolean;
  @Attr() lastLoginActiveAndConnected: boolean;
  @Attr() lastLoginUsername: string;
  @Attr() lastLoginName: string;
  @Attr() lastLoginInitials: string;
  @Attr() lastLoginAvatarUrl: string;
  @Attr() lastConnectedAt: string;
  @Attr() lastDisconnectedAt: string;

  @Attr() totalHours: number;
  @Attr() recentHours: number;
  @Attr() mtdHours: number;

  @BelongsTo() creationGroup: WorkstationCreationGroup;
  @BelongsTo() instanceType: InstanceType;
  @BelongsTo() image: Image;
  @BelongsTo() template: Template;
  @BelongsTo() facility: Facility;
  @BelongsTo() availabilityZone: AvailabilityZone;
  @HasOne() creation: WorkstationCreation;
  @HasOne() deletion: WorkstationDeletion;
  @HasOne() pendingDeletion: WorkstationDeletion;
  @HasOne() lastLogin: WorkstationLogin;
  @HasOne() lastRebuild: WorkstationBoot;
  @HasOne() lastConnection: WorkstationConnection;
  @HasMany() events: WorkstationEventFeedEntry;
  @HasMany() workstationGroupAssignments: WorkstationGroupAssignment[];
  @HasMany() groups: Group[];
  @HasOne() cpuStat: Stat;
  @HasOne() workstationHoursStat: Stat;

  @Link() delete: string;
  @Link() boot: string;
  @Link() shutdown: string;
  @Link() reboot: string;
  @Link() rebuild: string;

  constructor(attrs?: Record<string, any>) {
    const defaults = { selected: false };
    super({ ...defaults, ...attrs });
  }

  isOnPrem() {
    return this.deploymentType === 'on_prem';
  }

  isStopped() {
    return this.instanceState === 'stopped';
  }

  isRunning() {
    return this.instanceState === 'running';
  }

  isPending() {
    return (
      this.instanceState === 'pending' || (this.pending && this.isStopped())
    );
  }

  isStopping() {
    return (
      this.instanceState === 'stopping' || (this.pending && this.isRunning())
    );
  }

  lastUserInfo() {
    if (this.lastLoginUsername === null) return '-';
    return `${this.lastLoginUsername} - ${moment
      .utc(this.lastLoginAt)
      .local()
      .calendar()}`;
  }

  hasGroups() {
    return this.groups.length > 0;
  }

  iceMessage() {
    if (this.isRunning()) {
      return 'Your workstation has been re-provisioned. Please wait whilst we complete reconfiguration...';
    }

    if (this.pendingIceBootAttempts > 0) {
      return `Please wait, attempting to provision workstation. ${this.pendingIceBootAttempts} attempts remaining...`;
    }

    if (this.pendingIceBootAttempts === 0 && this.multiAz) {
      return `Please wait, attempting to find capacity...`;
    }
  }

  screens(): any[] {
    if (!(this.lastConnection && this.lastConnection.screens)) {
      return [];
    }

    return this.lastConnection.screens.map(screen => {
      const [x, y] = screen.split(' x ');
      return [parseInt(x), parseInt(y)];
    });
  }

  formattedCreatedAt() {
    return this.createdAt
      ? moment
          .utc(this.createdAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedRebuiltAt() {
    return this.rebuiltAt
      ? moment
          .utc(this.rebuiltAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  disableConnect() {
    if (this.pendingBoot || this.creation?.isPending()) {
      return true;
    }
    if (this.instanceState === 'pending' || this.instanceState === 'running') {
      return false;
    }
    return !this.links.boot;
  }

  disabledReason() {
    if (!this.disableConnect()) {
      return null;
    }
    if (this.creation?.isPending()) {
      return 'Workstation is being created';
    }

    if (this.pendingRebuild) {
      return 'Workstation is being rebuilt';
    }
    if (this.pendingBoot) {
      return 'Workstation is currently booting';
    }
    return null;
  }

  formattedLoggedInAt() {
    return moment
      .utc(this.lastLoginAt)
      .local()
      .format('MMM Do HH:mm');
  }

  formattedLoggedOutAt() {
    return moment
      .utc(this.lastLoggedOutAt)
      .local()
      .format('MMM Do HH:mm');
  }

  formattedConnectedAt() {
    return moment
      .utc(this.lastConnectedAt)
      .local()
      .format('MMM Do HH:mm');
  }

  formattedDisconnectedAt() {
    return moment
      .utc(this.lastDisconnectedAt)
      .local()
      .format('MMM Do HH:mm');
  }

  fakeAvatar() {
    return User.fakeAvatar(this.name);
  }
}
