import React from 'react';
import Updating from '../../components/pages/updating';
import { createRoot } from 'react-dom/client';

const with_reload = fn => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        console.log('Error', error);
        const container = document.getElementById('root');
        const root = createRoot(container);

        setTimeout(() => {
          window.location.reload();
        }, process.env.REACT_APP_RELOAD_TIMEOUT || 3000);
        return root.render(<Updating />);
      });
  });
};

export default fn => React.lazy(() => with_reload(fn));
