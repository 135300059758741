import {
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  header: { display: 'block' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(6),
  },
});

const Updating = ({ classes }) => {
  return (
    <Backdrop
      open
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <Paper className={classes.container}>
        <CircularProgress color="primary" sx={{ mb: 2 }} />
        <Typography variant="h6" className={classes.header}>
          An update is available
        </Typography>
        <Typography variant="body2">
          Please wait as the application reloads...
        </Typography>
      </Paper>
    </Backdrop>
  );
};

export default withStyles(Updating, styles, { withTheme: true });
